.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-toggler {
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  cursor: pointer;
  margin: 0;
}

.navbar-toggler span {
  display: block;
  width: 25px;
  height: 3px;
  background: black;
  margin: 6px 0;
  transition: all 0.3s ease;
}

.navbar-toggler.collapsed span:nth-of-type(1) {
  transform: translateY(8px) rotate(45deg);
}

.navbar-toggler.collapsed span:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.collapsed span:nth-of-type(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.navbar-toggler i.fa-times {
  font-size: 18px;
  color: black;
  transition: all 0.3s ease;
  opacity: 0;
}

.navbar-toggler.collapsed i.fa-times {
  opacity: 1;
}

